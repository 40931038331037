import { Link } from 'gatsby';
import React from 'react';
import { STRINGS } from '../variables/strings';

const Footer = () => (
    <nav className="my-8 flex flex-col items-center justify-center gap-8 text-base font-light text-white lg:flex-row">
        <Link to="/">{STRINGS.footer.start}</Link>
        <Link to="/o-aplikacji">{STRINGS.footer.about}</Link>
        <Link to="/rejestracja">{STRINGS.footer.register}</Link>
        <Link to="/instrukcja">{STRINGS.footer.instruction}</Link>
        <Link to="/polityka-prywatnosci">{STRINGS.footer.privacyPolicy}</Link>
        <Link to="/kontakt">{STRINGS.footer.contact}</Link>
    </nav>
);

export default Footer;
