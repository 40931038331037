import React, { ReactNode } from 'react';
import background from '../../../static/images/bg.png';
import Footer from '../Footer';

interface LayoutProps {
    children?: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => (
    <main className="flex flex-grow flex-col">
        <div className="flex h-full items-center justify-center">
            <div className="absolute left-0 top-0 -z-10 h-full w-full overflow-hidden bg-transparent">
                <div className="absolute -bottom-4 h-full w-full bg-gradient-to-t from-primary" />
                <img className="w-full" src={background} alt=""/>
            </div>
            <div className="container p-8">{children}</div>
        </div>
        <Footer />
    </main>
);

export default Layout;
