const SITE_URL = 'https://gryzli.pl';
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=pl.gryzli&hl=pl';
export const APP_STORE_URL = 'https://apps.apple.com/pl/app/id6450773428?l=pl';

export const STRINGS = {
    metadata: {
        title: 'Gryzli',
        description: 'Rozwiązuj quizy, rywalizuj i poszerzaj wiedzę!',
        type: 'website',
        siteUrl: process.env.SITE_URL || SITE_URL,
        image: '/images/logo.png',
    },
    landingPage: {
        h1: 'Rozwiązuj Quizy',
        h2: 'Rywalizuj i poszerzaj wiedzę!',
        description:
            'Aplikacja Gryzli to specjalnie przygotowane quizy i treningi, dzięki którym poszerzysz swoją wiedzę w ciekawy sposób. W czasie quizu rywalizujesz ze swoimi znajomymi. Jest to świetna forma poszerzania wiedzy jak i przygotowania do m.in. matury, egzaminów i wszystkiego innego!',
        logoAlt: 'Logo Gryzli',
        previewAlt: 'Podgląd aplikacji na urządzeniu mobilnym. Na górze napisane "wybierz quiz", poniżej karta quizu z napisem "fizyka, quiz podstawowy, w trakcie"',
    },
    footer: {
        about: 'O aplikacji',
        privacyPolicy: 'Polityka prywatności',
        contact: 'Kontakt',
        instruction: 'Instrukcja',
        start: 'Start',
        register: 'Rejestracja',
    },
    notFound: {
        h1: 'Strona nie została znaleziona',
        description: 'Przepraszamy, ale strona, której szukasz, nie istnieje.',
        button: 'Wróć do strony głównej',
    },
    downloadLinks: {
        google: {
            alt: 'Pobierz z Google Play',
        },
        apple: {
            alt: 'Pobierz z App Store',
        },
    },
};
